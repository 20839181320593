import { exhaustiveCheck } from '@og-shared/utils';

export function getIonColor(color: 'primary' | 'success' | 'danger') {
  return getComputedStyle(document.body)
    .getPropertyValue(`--ion-color-${color}`)
    .replace(' ', '');
}

export function getIonColorRgba(
  color: 'primary' | 'success' | 'danger',
  opacity: number
) {
  const rgb = getComputedStyle(document.body).getPropertyValue(
    `--ion-color-${color}-rgb`
  );
  return `rgba(${rgb}, ${opacity})`;
}

export const primaryColor = getIonColor('primary');

export const getTextColor = () =>
  getComputedStyle(document.body)
    .getPropertyValue('--ion-text-color')
    .replace(' ', '');

export const getColorStep = (step: number) =>
  getComputedStyle(document.body)
    .getPropertyValue(`--ion-background-color-step-${step}`)
    .replace(' ', '');

export const getColor = (
  color: number | undefined,
  shade: 'primary' | 'shade' | 'rgba',
  opacity?: number
) => {
  switch (shade) {
    case 'primary': {
      return getComputedStyle(document.body)
        .getPropertyValue(`--budget-color-${color}-primary`)
        .replace(' ', '');
    }
    case 'shade': {
      return getComputedStyle(document.body)
        .getPropertyValue(`--budget-color-${color}-primary`)
        .replace(' ', '');
    }
    case 'rgba': {
      const rgb = getComputedStyle(document.body).getPropertyValue(
        `--budget-color-${color}-rgb`
      );
      return `rgba(${rgb}, ${opacity})`;
    }
    default: {
      return exhaustiveCheck(shade);
    }
  }
};
